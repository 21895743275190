export default {
  primaryColor: '#2E2E2E',
  primaryColorDarker: '#333333',
  primaryColorLight: '#3551ad',
  customColorText: '#4B6FDD',
  titleText: '#333333', //
  paragraphText: '#666', //
  lpColors: {
    headerLp: '#FFFFFF',
    headerTextColor: '#333',
    openingScreen: '#F6F6F6',
    infinityDataScreen: '#333333',
    infinityDataText: '#FFFFFF', //
    infinityDataCircle: '#656666', //
    infinityDataIcon: '#fff', //
    whatIsScreen: '#fff',
    whatIsScreenTextColor: '#656666',
    whatIsIcon: '#2E2E2E',
    stepColor: '#656666', //
    stepByStepScreen: '#F6F6F6',
    faqScreen: '#fff',
    footerLp: '#333333',
    simulatorButton: '#2E2E2E',
    color1: '#4B6FDD', //green1
    color2: '#308CB0', //green2
    color3: '#07203C', //blue1
    color4: '#1A2E4E', //blue2
    color5: '#2B4E85', //blue3
    color6: '#F3F8FF', //light gray
  },
};
