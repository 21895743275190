import SystemStepCategory from 'model/enums/system-step-category';
import { StepCard } from './styles';

interface StepDisplayProps {
  step: SystemStepCategory;
  selectedStep: SystemStepCategory | null;
  setSelectedStep: (step: SystemStepCategory) => void;
  stepName?: string;
}

export const StepDisplay = ({ step, selectedStep, setSelectedStep, stepName }: StepDisplayProps) => {
  return (
    <>
      {stepName && (
        <StepCard onClick={() => setSelectedStep(step)} selected={selectedStep === step}>
          {stepName}
        </StepCard>
      )}
    </>
  );
};
